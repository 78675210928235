<!-- use tags -->
export interface Input {
  tokens: {
    description: string;
    expires?: string;
    readOnly: boolean;
    value: string;
  }[];
}
static declare global {
  interface Window {
    explorer: Window | null;
  }
}

<let/selectedToken=(input.tokens[0]?.value)/>
<effect() {
  const listener = (event: MessageEvent) => {
    if (event.origin === $global.baseUrl && event.data === "ready") {
      window.explorer?.postMessage(selectedToken, event.origin);
    }
  };
  window.addEventListener("message", listener, false);

  return () => {
    window.removeEventListener("message", listener);
  };
}/>

<sb-top-bar-dropdown
  class="hal-explorer"
  icon="globe"
  iconExpanded="globe--solid"
  title="Explore API"
>
  <div class="explorer">
    <div class="explorer__title">
      <div class="label">
        Explore API with
      </div>
      <sb-link href="/settings/api-tokens">
        Manage tokens
      </sb-link>
    </div>

    <div onClick(event: Event) {
      event.stopPropagation();
    }>
      <if=(input.tokens.length)>
        <for|{ description, value }, index| of=input.tokens>
          <div>
            <label>
              <input
                class="input"
                checked=index === 0
                name="token"
                onClick(event: Event) {
                  selectedToken = (event.target as HTMLInputElement).value;
                }
                type="radio"
                value=value
              >
              ${" "}${description}
            </label>
          </div>
        </for>
      </if>
      <else>
        <sb-notification level="warning">
          All your tokens have expired. Please regenerate your tokens to continue.
        </sb-notification>
      </else>
    </div>

    <if=(input.tokens.length)>
      <button
        aria-label="Open HAL Explorer in new window or tab"
        class="button explorer__cta"
        onClick() {
          window.explorer = window.open(
            `/hal-explorer/index.html?v=${new Date().getTime()}`,
            "hal-explorer",
          );
        }
      >
        Open HAL Explorer
        <sb-external/>
      </button>
    </if>
    <sb-link
      class="explorer__help"
      href="https://smartbear.portal.swaggerhub.com/pactflow/default/getting-started"
      rel="noopener noreferrer"
      target="_blank"
    >
      API Documentation
      <span class="visually-hidden">
        - opens in new window or tab
      </span>
      <sb-external/>
    </sb-link>
  </div>
</sb-top-bar-dropdown>

<style.scss>
  .explorer {
    padding: $spacing-16;

    &__title {
      align-items: center;
      display: flex;
      gap: $spacing-24;
      margin-bottom: $spacing-8;

      & > * {
        text-wrap: nowrap;
      }
    }

    &__option {
    }

    &__cta {
      display: block;
      margin: $spacing-8 0;
      width: 100%;
    }

    &__help {
      display: block;
      margin-top: $spacing-16;
      text-align: center;
    }
  }
</style>
