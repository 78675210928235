<!-- use tags -->
export interface Input {
  class?: string;
  icon: "globe" | "menu-grip-dots" | "message-dots" | "user-circle";
  iconExpanded?: "globe--solid" | "message-dots--solid" | "user-circle--solid";
  large?: boolean;
  left?: boolean;
  renderBody: Marko.Body;
  title: string;
}
<id/rootId/>
<id/menuId/>
<let/expanded=false/>

<div
  class="top-bar-dropdown"
  id=rootId
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      expanded = false;
    }
  }
>
  <button
    aria-controls=menuId
    aria-expanded=(expanded ? "true" : "false")
    aria-haspopup="true"
    aria-label=input.title
    class=[
      "top-bar__button",
      expanded && "top-bar__button--current",
      input.large && "top-bar__button--large",
    ]
    onClick() {
      expanded = !expanded;
    }
    type="button"
  >
    <sb-icon
      icon=(expanded && input.iconExpanded ? input.iconExpanded : input.icon)
      title=input.title
    />
  </button>
  <div
    class=[
      input.class,
      "top-bar-dropdown__dropdown",
      expanded && "top-bar-dropdown__dropdown--open",
      input.left && "top-bar-dropdown__dropdown--left",
    ]
    id=menuId
    onClick() {
      expanded = false;
    }
  >
    <${input.renderBody}/>
  </div>
</div>
<watch-focus
  for=rootId
  onOutside() {
    expanded = false;
  }
/>

<style.scss>
  .top-bar-dropdown {
    color-scheme: light dark;
    display: inline-block;
    position: relative;

    &__dropdown {
      --bg: #{$color-neutral-00};
      --border: #{$color-neutral-20};
      --fg: #{$color-neutral-98};

      background: var(--bg);
      border: 1px solid var(--border);
      border-radius: $radius-s;
      box-shadow: $elevation-dropdown;
      color: var(--fg);
      display: none;
      position: absolute;
      right: $spacing-8;
      top: calc(100% + $spacing-8);
      z-index: 2;

      &--left {
        left: $spacing-8;
        right: initial;
      }

      &--open {
        display: block;
      }

      @media (prefers-color-scheme: dark) {
        --bg: #{$color-neutral-98};
        --border: #{$color-neutral-70};
        --fg: #{$color-neutral-00};
      }
    }
  }
</style>
