<!-- use tags -->
export interface Input {
  for: string;
  onOutside: () => void;
}
<effect() {
  const el = document.getElementById(input.for);
  const notify = (event: MouseEvent | FocusEvent) => {
    if (!(el as HTMLElement).contains(event.target as Node)) {
      input.onOutside();
    }
  };
  document.addEventListener("click", notify);
  document.addEventListener("focusin", notify);
  return () => {
    document.removeEventListener("click", notify);
    document.removeEventListener("focusin", notify);
  };
}/>
